// App.js
import React from 'react';
import './App.css';
import './CurrentlyEditing.css';
import { HuePicker } from 'react-color';

class CurrentlyEditing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {title: '', subheader: '', name: '', infobox: '', hue: '', total_time: 20};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleHueChange = (color, event) => {
    console.log(color)
    this.setState({ hue: color.hsl.h });
  };

  handleSubmit(event) {
    event.preventDefault();
    let id = this.props.currently_editing_id;
    let et = this.props.currently_editing_type;
    let room = this.props.room;
    let total_time = this.state.total_time
    if(!total_time) total_time = 20; // 20 minutes default
    let total_time_seconds = parseInt(total_time) * 60; // convert minutes to seconds
    // validate
    if(et=="user" && !room.users[id]) return null;
    if(et=="block" && !room.blocks[id]) return null;
    if(et=="section" && !room.sections[id]) return null;
    // okay send the submission
    if(et=="user"){
      this.props.edit_user(id, this.state.infobox||"", this.state.name||"")
    }else if(et=="block"){
      this.props.edit_block(id, this.state.hue||"", this.state.infobox||"")
    }else if(et=="section"){
      this.props.edit_section(id, this.state.hue||"", this.state.infobox||"", this.state.title||"")
    }else if(et=="room"){
      this.props.edit_room(this.state.title||"", this.state.subheader||"", total_time_seconds)
    }
    this.props.close_editing()

  }

  componentWillReceiveProps(nextProps){
    if(!nextProps) return true;
    let n_et = nextProps.currently_editing_type;
    let n_id = nextProps.currently_editing_id;
    let et = this.props.currently_editing_type;
    let id = this.props.currently_editing_id;

    if(n_et!=et || n_id!=id){
      this.setState({title: '', subheader: '', name: '', infobox: '', hue: ''});

      let room = this.props.room;
      if(n_et=="user" && room.users[n_id]){ 
        let user = room.users[n_id];
        this.setState({name: user.name||("Participant "+n_id), infobox: user.infobox||""})

      }else if(n_et=="block" && room.blocks[n_id]){
        let block = room.blocks[n_id];
        let user = room.users[block.user]
        let username = user ? user.name : "";
        this.setState({infobox: block.infobox||"", hue: block.hue, name: username})

      }else if(n_et=="section" && room.sections[n_id]){
        let section = room.sections[n_id];
        this.setState({infobox: section.infobox||"", title: section.title||"", hue: section.hue})
      
      }else if(n_et=="room"){
        this.setState({title: room.title||"Title", subheader: room.subheader||"", total_time: Math.floor(room.total_time/60)})
      }
    }
    return true
  }

  render() {
    // check props
    let et = this.props.currently_editing_type;
    let id = this.props.currently_editing_id;
    let room = this.props.room;
    let et_capitalized;
    // if props are not set, then don't render
    if(!et) return null;
    if(!room) return null;
    if(id===undefined) return null;
    // header uses capitalized type
    if(et=="room"){
      et_capitalized = "Session"
    }else{
      et_capitalized = et.charAt(0).toUpperCase() + et.slice(1); 
    }
    // check that the current object actually exists
    if(et=="user" && !room.users[id]) return null;
    if(et=="block" && !room.blocks[id]) return null;
    if(et=="section" && !room.sections[id]) return null;

    let color = this.state.hue ? "hsl("+this.state.hue+"deg 50% 50%)" : "black"
    let submit_text = "SUBMIT"
    // okay now build the form
    let form = null;
    if(et=="room"){
      form = (
        <form onSubmit={this.handleSubmit}>
          <label>
            Title
            <input type="text" name="title" value={this.state.title} onChange={this.handleChange} />
          </label>
          <label>
            Subheader
            <textarea name="subheader" value={this.state.subheader} onChange={this.handleChange} />
          </label>
          <label>
            Total Length of Session (minutes)
            <input type="text" name="total_time" pattern="[0-9]*" value={this.state.total_time} onChange={this.handleChange} />
          </label>
          <input type="submit" className="submit-edit-button" value={submit_text} />
        </form>);

    }else if(et=="user"){
      form = (
        <form onSubmit={this.handleSubmit}>
          <label>
            <div className="form-uneditable">ID# {id}</div>
          </label>
          <label>
            Name
            <input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
          </label>
          <label>
            Infobox
            <textarea value={this.state.infobox} name="infobox" onChange={this.handleChange} />
          </label>
          <input type="submit" className="submit-edit-button" value={submit_text} />
          <input type="button"  className="delete-button" value="REMOVE PARTICIPANT" onClick={()=>this.props.remove_user(id)} />

        </form>);

    }else if(et=="section"){
       form = (
        <form onSubmit={this.handleSubmit}>
          <label>
            Title
            <input type="text" value={this.state.title} name="title" onChange={this.handleChange} style={{backgroundColor:color, fontWeight: "bold"}}/>
          </label>
          <HuePicker width="100%" color={color} onChangeComplete={ this.handleHueChange } />

          <label>
            Infobox
            <textarea value={this.state.infobox} name="infobox"  onChange={this.handleChange} />
          </label>
          <input type="submit" className="submit-edit-button" value={submit_text} />
          <input type="button"  className="delete-button" value="DELETE SECTION" onClick={()=>this.props.remove_section(id)} />

        </form>);


    }else if(et=="block"){
       form = (
        <form onSubmit={this.handleSubmit}>
          <label>
            <div className="form-uneditable">{this.state.name}</div>
          </label>
                    <HuePicker width="100%" color={color} onChangeComplete={ this.handleHueChange } />

          <label>
            
            <textarea style={{backgroundColor: color}} value={this.state.infobox||this.state.name} name="infobox" onChange={this.handleChange} />

          </label>
          <input type="submit" className="submit-edit-button" value={submit_text} />
          <input type="button" className="delete-button" value="DELETE BLOCK" onClick={()=>this.props.remove_block(id)} />

        </form>);


    }else{
      // bad type
      return null;
    }

    return (
      <div className={["currently-editing-container "+(this.props.show?"show":"hide")]} >
        <div class="currently-editing-x" onClick={this.props.close_editing}>✕</div>
        <div class="currently-editing-header">Edit {et_capitalized}</div>
        {form}
      </div>

    );
  }
}

export default CurrentlyEditing;