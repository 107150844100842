// App.js
import React from 'react';
import './App.css';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import TimelineContainer from './TimelineContainer.js';
import Hello from './Hello.js';

import firebase from "firebase/app";
import "firebase/database";
import { FirebaseDatabaseProvider, FirebaseDatabaseNode } from "@react-firebase/database";

const firebase_config = {
  apiKey: "AIzaSyBWi3WBWJp9teOhIdoMBcGd8VPHPBjbg1M",
  authDomain: "thisandthat-prototype-phase-1.firebaseapp.com",
  databaseURL: "https://thisandthat-prototype-phase-1.firebaseio.com",
  projectId: "thisandthat-prototype-phase-1",
  storageBucket: "thisandthat-prototype-phase-1.appspot.com",
  messagingSenderId: "576402319359",
  appId: "1:576402319359:web:147156b946e4a1e404a9ca",
  measurementId: "G-72RB8R25X6"
};
const s = (a: any) => JSON.stringify(a, null, 2);



class App extends React.Component {

  render() {
    //let db = firebase.database().ref(path);

    // :roomid is the Room we want to load
    // :userid is my User's ID
    // :extra isn't used, it's just something that looks nice in the url 
    return (
      <Router>
      <Switch>

        <Route path={["/hello"]} component={(routerProps) =>
          <FirebaseDatabaseProvider firebase={firebase} {...firebase_config}>
            <FirebaseDatabaseNode path={"/"}>
              {fb => {
                return (
                  fb.value && <Hello 
                        db={firebase.database().ref("/")} 
                        fb={fb.value} 
                        params={routerProps.match.params}/> )}}
            </FirebaseDatabaseNode>
          </FirebaseDatabaseProvider>
        }/>

        <Route path={["/create"]} component={(routerProps) =>
          <FirebaseDatabaseProvider firebase={firebase} {...firebase_config}>
            <FirebaseDatabaseNode path={"/"}>
              {fb => {
                return (
                  fb.value && <Hello 
                        db={firebase.database().ref("/")} 
                        fb={fb.value} 
                        subdir="create"
                        params={routerProps.match.params}/> )}}
            </FirebaseDatabaseNode>
          </FirebaseDatabaseProvider>
        }/>


        <Route path={["/room/:roomid/:userid/:mod/:extra",
                      "/room/:roomid/:userid/:mod",
                      "/room/:roomid/:userid"]} 
                    component={(routerProps) =>
          <FirebaseDatabaseProvider firebase={firebase} {...firebase_config}>
            <FirebaseDatabaseNode path={"/rooms/"+routerProps.match.params.roomid}>
              {room => {
                return (
                  room.value && <TimelineContainer 
                        db={firebase.database().ref("/rooms/"+routerProps.match.params.roomid)} 
                        room={room.value} 
                        params={routerProps.match.params}/> )}}

            </FirebaseDatabaseNode>
          </FirebaseDatabaseProvider>
        }/>
      </Switch>
    </Router>
    );
  }
}

export default App;