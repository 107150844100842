// App.js
import React from 'react';
import './App.css';
import './Countdown.css';

class Countdown extends React.Component {

  state = {};

  render() {
    
    var percent_completed, names, numerical_time, seconds, minutes, section_duration, nowtime, sdo, countdown_color, alert_numerical_time, alert_color;
    var section_title = "--";

    if(this.props.is_empty){
      // we are currently not inside of a section
      numerical_time = "--";
      percent_completed = "0";
      names = "--";
      // If there is a section coming up, countdown to it
      if(this.props.next_section){
        let next_time = this.props.next_section.start; 
        if(next_time >= this.props.room.total_time){
          // the next section is beyond the boundary of the room, ignore it
        }else{
          // update the numerical time display 
          seconds = Math.ceil(next_time - this.props.nowtime);
          minutes = Math.floor(seconds/60.0);
          seconds = seconds%60
          numerical_time = String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');
          if(this.props.next_section.title){
            section_title = "Next up: " + this.props.next_section.title;
          }
        }
      }
    }else{
      // we are currently inside of a real section
      nowtime = this.props.nowtime

      if(this.props.room.clock_follow=="question_topics"){
        // Use these values if the clock follows QUESTION/TOPIC sections
        section_duration = this.props.section.duration
        percent_completed = (nowtime - this.props.section.start) / (this.props.section.duration);
      }else{
        // Use these values if the clock counts up toward the next event (could be change in user block or section)
        section_duration = this.props.clock_section_duration
        percent_completed = (nowtime - this.props.clock_section_start) / (section_duration);
      }

      // update the numerical time display 
      //seconds = Math.floor(section_duration*(percent_completed)); //counting up 
      seconds = Math.ceil(section_duration*(1-percent_completed));  // counting down
      minutes = Math.floor(seconds/60.0);
      seconds = seconds%60
      numerical_time = String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');

      section_title = this.props.section.title||""; 

     
    }


    if(this.props.names.length >= 2 && this.props.names.length == this.props.room.users.length){
      // if it's everybody's turn, use "everybody"
      names = "Everybody"
    }else{
      names = this.props.names;        
      // Check if my user_id is part of this room
      if(this.props.room.users[this.props.my_user_id]){
        // Yes
        let username = this.props.room.users[this.props.my_user_id].name;
        // put my username on the top 
        names = names.sort((x,y) => (y==username) - (x==username));
      }       
      // combine names with &
      names = names.join(" & ");
    }

    // update the circular clock display
    sdo = ((1- percent_completed)*1131.0)+"px";

    let hue = 0;
    let user_color = "black";
    let bg_color = "white";
    let circle2_color = "#ddd";
    let my_user_is_talking = false;
    // Set the color of the countdown clock
    if(this.props.is_empty){
      // no section, use light grey
      user_color = "#ccc";
    }
      
  // Check if there is a section 
  // color of the countdown depends on if the user is currently speaking

    if(this.props.current_users.user_ids.indexOf(this.props.my_user_id)!=-1){
      // find my user's current block
      for(let i=0; i<this.props.current_users.blocks.length; i++){
        let block = this.props.current_users.blocks[i]

        if(block.user==this.props.my_user_id){
          // my user is talking and this is their block
          my_user_is_talking = true
          hue = block.hue;
          user_color = "hsl("+hue+"deg 50% 50%)";
          alert_color = user_color;
          //bg_color = "hsl("+hue+"deg 50% 95%)";
          //ircle2_color = "hsla("+hue+"deg 50% 50% 0.1)";
          //break
        }
      }
    }
    
    let section_color = "black";
    /*if(this.props.next_section && this.props.next_section.hue){
      section_color = "hsl("+this.props.next_section.hue+"deg 50% 50%)";
    }else{
      section_color = "hsl("+this.props.section.hue+"deg 50% 50%)";
    }*/
    

    alert_numerical_time = false;
    // Show the user an alert about the next time they will talk 
    if(this.props.my_next_block && !my_user_is_talking && (this.props.is_empty || this.props.my_next_block.start - this.props.nowtime <= 60)){
      seconds = Math.ceil(this.props.my_next_block.start - this.props.nowtime);
      minutes = Math.floor(seconds/60.0);
      seconds = seconds%60;
      let hue = this.props.my_next_block.hue;
      alert_color = "hsl("+hue+"deg 50% 50%)";
      alert_numerical_time = String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');
    }

    return (
      <div id="countdown"
        style={{"backgroundColor": bg_color}}
        onMouseEnter={()=>this.props.change_infobox(this.props.section.title||"")}>
        <div id="countdown-tt-logo"></div>
        <div id="countdown-clock-container">
          <div id="countdown-percent">
            <span id="countdown-percent-value">{percent_completed}</span>
          </div>
          <div id="countdown-name" style={{"color": user_color}}>
            <div className="name-nest-1">
              <div className="name-nest-2">
                {names}
              </div>
            </div>
          </div>
          <div id="countdown-number" style={{"color": user_color}}>{numerical_time}</div>
          <div id="countdown-question" style={{"color": section_color}} >{section_title}</div>
          <svg>

            <circle r="180" cx="200" cy="200" id="countdown-circle-bg2"
              style={{"stroke": circle2_color}}></circle>
            <circle r="180" cx="200" cy="200" id="countdown-circle" 
              style={{"strokeDashoffset": sdo, "stroke": user_color}}></circle>
            <circle r="180" cx="200" cy="200" id="countdown-circle-bg"
              style={{"stroke": bg_color}}></circle>

          </svg>

          {this.props.is_moderator && <div id="countdown-buttons">
            { this.props.is_paused && <span id="countdown-buttons-play" onClick={this.props.click_playpause} > </span> }
            { !this.props.is_paused && <span id="countdown-buttons-pause" onClick={this.props.click_playpause}> </span> } 
            <span id="countdown-buttons-back"  onClick={this.props.click_skip_backward}> </span>
            <span id="countdown-buttons-forward" onClick={this.props.click_skip_forward}> </span>
          </div>}

          {!this.props.is_moderator && alert_numerical_time && <div id="countdown-alert" style={{"backgroundColor": alert_color}}>
                    <span id="countdown-alert-text">Get ready to talk in </span>
                    <span id="countdown-alert-number">{alert_numerical_time}</span>
          </div>}

          {!this.props.is_moderator && my_user_is_talking && <div id="countdown-alert" style={{"backgroundColor": alert_color}}>
                    <span id="countdown-alert-text">You are talking</span>
          </div>}

          {!this.props.is_moderator && !my_user_is_talking && !alert_numerical_time && <div id="countdown-alert" class="countdown-alert-logo">
            &nbsp;
          </div>}
        </div>


      </div>

    );
  }
}

export default Countdown;