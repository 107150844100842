// App.js
import React from 'react';
import './App.css';
import './Hello.css';
import { HuePicker } from 'react-color';

import {
  Link,
  useHistory
} from "react-router-dom";
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router";



function get_awesome_name(i){
  let names = ["Harry","CJ","Rafe","Nas"]
  if(i<names.length) return names[i]
  else return "Participant "+i;
}

class CreateRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {title: '', number_of_users: 2, total_time: 20};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    let number_of_users = parseInt(this.state.number_of_users) || 3;
    let total_time = parseInt(this.state.total_time)*60; // convert to seconds
    let title = this.state.title||"New Session";
    this.createRoom(title,  total_time, number_of_users)
  }

  createRoom = (title,  total_time, number_of_users) => {
    total_time = parseInt(total_time);
    total_time = Math.floor(total_time/10)*10; 
    let new_room_id;
    // todo: authentication
    if(!this.props.fb.rooms){
      new_room_id = 0;
    }else{
      new_room_id = Math.max(...Object.keys(this.props.fb.rooms))+1;  
    }
    let hue = Math.floor(Math.random()*360);
    let users = []
    for(let i=0;i<number_of_users;i++){
      users.push({
        "name": get_awesome_name(i),
        "infobox": ""
      })
    }
    let room = {
      "blocks": [
        {
          "hue": hue,
          "start": 0,
          "duration": 60,
          "user": 0,
          "infobox": ""
        }
      ],
      "sections": [
        {
          "hue": hue,
          "start": 0,
          "duration": 60,
          "title": "Introductions",
          "infobox": ""
        }
      ],
      "users": users,
      "total_time": total_time,
      "title": title,
      "subheader": ""
    }
    
    console.log(room, new_room_id)
    this.props.db.child("rooms/" + new_room_id).set(
      room).then(()=>{
        console.log("nice")
        this.props.history.push("/room/"+new_room_id+"/0/mod")
      }
    );
  }

  render() {
    // check props

    let form = 
      <form onSubmit={this.handleSubmit}>
        <label>
          Session Title
          <input type="text" name="title" value={this.state.title} onChange={this.handleChange} />
        </label>
        <label>
          Number Of Users
          <input type="text" name="number_of_users" pattern="[0-9]*" value={this.state.number_of_users} onChange={this.handleChange} />
        </label>
        <label>
          Length of Session (minutes)
          <input type="text" name="total_time" pattern="[0-9]*" value={this.state.total_time} onChange={this.handleChange} />
        </label>
        <input type="submit" className="submit-edit-button" value={"CREATE"} />
      </form>


    return (
      <div>
        <div className="hello-header">Create New Session</div>
        {form}
      </div>
    );
  }
}

const CreateRoomWithRouter = withRouter(CreateRoom);

export default CreateRoomWithRouter;