// App.js
import React from 'react';
import './App.css';
import './Hello.css'
import CreateRoom from './CreateRoom.js';

import {
  Link,
  useHistory
} from "react-router-dom";
import { Redirect } from 'react-router-dom'
import { withRouter } from "react-router";

class Hello extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let subdir = this.props.subdir||""

    return (

      <div className="hello-container">
       <div className="this-and-that-logo"></div>
          {subdir == "create" && <CreateRoom {...this.props}/> }
          {subdir == "list" && <ListRooms {...this.props}/> }
          {subdir == "" && <MainMenu {...this.props}/> }
      
      </div>
    );
  }
}



class MainMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // check props

    return (
      <div>
        <Link to="/create">
          <div className="link-button">CREATE</div>
        </Link>
        <ListRooms {...this.props}/>
      </div>
    );
  }
}




class ListRooms extends React.Component {
  constructor(props) {
    super(props);
  }

  duplicateTemplate = (room_id) => {
    let new_room_id;
    if(!this.props.fb.rooms){
      new_room_id = 0;
    }else{
      new_room_id = Math.max(...Object.keys(this.props.fb.rooms))+1;  
    }
    let room = this.props.fb.rooms[room_id];
    room.title = room.title + " [clone]"
    this.props.db.child("rooms/" + new_room_id).set(
      room).then(()=>{
        console.log("nice")
        this.props.history.push("/room/"+new_room_id+"/0/mod")
      }
    );
  }

  deleteRoom = (room_id) => {
    console.log("deleteRoom", room_id)
    this.props.db.child("rooms/" + room_id).remove()
  }

  render() {
    // check props

    let rooms = []
    for(let room_id in this.props.fb.rooms){
      let room = this.props.fb.rooms[room_id]
      let users = []
      for(let user_id in room.users){
        let user = room.users[user_id]
        users.push(user.name)
      }
      let num_sections = 0;
      for(let section_id in room.sections){
        num_sections++;
      }
      let room_elem = <div className="list-room">
        <div className="list-title">{room.title}</div>
        {room.subheader && <div className="list-subheader">{room.subheader}</div>}
        <div className="list-subheader">{Math.floor(room.total_time/60)} minutes, {num_sections} section{num_sections>1?"s":""}</div>        
        <div className="list-subheader">{users.join(", ")}</div>
        <div className="list-buttons">
          <div onClick={()=>this.props.history.push("/room/"+room_id+"/0/mod")} className=" link-button">OPEN</div>
          <div onClick={()=>this.duplicateTemplate(room_id)} className=" link-button">CLONE</div>
          <div onClick={()=>this.deleteRoom(room_id)} className="link-button-red link-button">DELETE</div>
        </div>
      </div>
      rooms.push(room_elem); 
    }

    return (
      <div>
          {rooms}
      </div>
    );
  }
}








const HelloWithRouter = withRouter(Hello);
export default HelloWithRouter;