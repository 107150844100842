// App.js
import React from 'react';
import './App.css';
import './UsersOnline.css';

class UsersOnline extends React.Component {

  state = {};

  render() {
    
    let users_elements = []
    let users = this.props.room.users;
    let server_time = this.props.room.last_server_timestamp
    for(let i in users){
      let user=users[i];

      let this_user_id_class = "";
      if(i==this.props.my_user_id){
        this_user_id_class = "my-user-highlight"
      }
      if(user.last_seen > server_time - 3*1000){
        users_elements.push(<div class={"user-online "+this_user_id_class}>{user.name}</div>)
      }
    }

    return (
      <div className={["users-online-container "+(this.props.show?"show":"hide")]} >
        <div class="users-online-x" onClick={this.props.close_users}>✕</div>
        <div class="users-online-header">Online Now</div>

        <div class="users-online-list">
          {users_elements}
        </div>
      </div>

    );
  }
}

export default UsersOnline;