
// App.js
import React from 'react';
import './App.css';

import Timeline from './Timeline.js';
import Countdown from './Countdown.js';
import UsersOnline from './UsersOnline.js';
import CurrentlyEditing from './CurrentlyEditing.js';
import firebase from "firebase/app";

const empty_section = {
  duration: 0,
  start: 0,
  title: ""
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

// get the current section, using the room data and the current time
function get_current_section(room, nowtime){
  // There should only be one section playing at a time. 
  // I could be strict and test that fact here.
  // Or instead, let's be lax: iterate, find the first section which contains the current time
  for(let i in room.sections){
    let section = room.sections[i]
    if(!section) continue
    //console.log(nowtime, i, section.start, section.duration);
    if(section.start <= nowtime && (section.duration + section.start > nowtime)){
      //console.log("current_section", i, section)
      return section
    }
  }
  // no section is current; the playhead is somewhere without a section
  return empty_section
}

// get previous rewind time
// the next previous event (start/end of section or block )
function get_rewind_time(room, nowtime, eps=2){
  // Find the latest event which happens before nowtime, and set that as the rewind_time
  let rewind_time = 0;
  // eps is to prevent reall tiny rewinds, set a minimum rewind amount

  // First iterate though sections
  if(room.sections){ 
    for(let i in room.sections){
      let section = room.sections[i];
      if(!section) continue;
      let start_time = section.start
      let end_time = section.start + section.duration;
      if(start_time + eps <= nowtime && start_time > rewind_time && start_time <= room.total_time){
        rewind_time = start_time
      }
      if(end_time + eps <= nowtime && end_time > rewind_time && end_time <= room.total_time){
        rewind_time = end_time
      }
    }
  }
  // Then iterate though blocks
  if(room.blocks){
    for(let i in room.blocks){
      let block = room.blocks[i]
      if(!block) continue;
      let start_time = block.start
      let end_time = block.start + block.duration;
      if(start_time + eps <= nowtime && start_time > rewind_time && start_time <= room.total_time){
        rewind_time = start_time
      }
      if(end_time + eps <= nowtime && end_time > rewind_time && end_time <= room.total_time){
        rewind_time = end_time
      }
    }
  }
  return rewind_time
}


// get next fast foward time
// the next event (start/end of section or block)
function get_forward_time(room, nowtime, eps=0){
  // Find the earliest event which happens after nowtime, and set that as the rewind_time
  let forward_time = room.total_time;
  // Eps is prevent 1s skips, set a minimum forward amount

  // First iterate though sections
  if(room.sections){ 
    for(let i in room.sections){
      let section = room.sections[i]
      if(!section) continue;
      let start_time = section.start
      let end_time = section.start + section.duration;
      if(start_time + eps > nowtime && start_time < forward_time && start_time < room.total_time){
        forward_time = start_time
      }
      if(end_time + eps > nowtime && end_time < forward_time && end_time <= room.total_time){
        forward_time = end_time
      }
    }
  }
  if(room.blocks){ 
    // Then iterate though blocks
    for(let i in room.blocks){
      let block = room.blocks[i]
      if(!block) continue;
      let start_time = block.start
      let end_time = block.start + block.duration;
      if(start_time - eps > nowtime && start_time < forward_time && start_time < room.total_time){
        forward_time = start_time
      }
      if(end_time - eps > nowtime && end_time < forward_time && end_time <= room.total_time){
        forward_time = end_time
      }
    }
  }
  return forward_time
}

// Get the next event after this event
function get_next_section(room, nowtime){
  let forward_time = 999999;
  let next_section;
  let eps=0;
  if(room.sections){
    // First iterate though sections
    for(let i in room.sections){
      let section = room.sections[i]
      if(!section) continue;
      let start_time = section.start
      if(start_time + eps > nowtime && start_time < forward_time){ 
        if(start_time < room.total_time){
          // within the room size 
          forward_time = start_time;
          next_section = section;
        }
      }
    }
  }
  return next_section;
}


// Return the next block in my user's row
// If there is no next block, return undefined
function get_my_next_block(room, nowtime, my_user_id){
  let my_next_block = undefined;
  // iterate though blocks

  let eps = 0; // epsilon margin
  let earliest_time = 99999999;
  if(room.blocks){
    for(let i in room.blocks){
      let block = room.blocks[i];
      if(!block) continue;
      if(block.user != my_user_id){
        continue;
      }
      let start_time = block.start
      if(start_time > nowtime && start_time < earliest_time){
        if(start_time < room.total_time){
          earliest_time = start_time
          my_next_block = block;
        }
      }
    }
  }
  return my_next_block;
}


// get users currently shcheduled to talk right now
function get_current_users(room, nowtime){
  // multiple users can be scheduled at the same time
  // no users might be schedule
  let user_ids = [];
  let blocks = [];
  let users = []
  let user_names = []
  //console.log(room.blocks)
  if(!room.blocks) return {user_ids, users, user_names, blocks}
    for(let i in room.blocks){
    let block = room.blocks[i]
    if(!block) continue;
    if(block.start <= nowtime && block.duration + block.start > nowtime){
      // this block is current.
      // add the useri_d to the list. 
      //if(room.users[block.user]){
        // only add the blocks when the user is on the list
      user_ids.push(parseInt(block.user))
      
      blocks.push(block)

    }
  }
  // each user_ids should only appear once.
  // I could be strict and test that fact here.
  // But instead, let's be lax; filter out duplicates just in case there was a block error
  //user_ids = user_ids.filter(onlyUnique);
  for(let i=0; i<user_ids.length; i++){
    let user_id = user_ids[i]
    let user = room.users[user_id]
    users.push(user);
    user_names.push(user.name)
  }

  // if no users are scheduled. that's ok. return empty lists. 
  return {user_ids, users, user_names, blocks}
}



class TimelineContainer extends React.Component {
  constructor(props) {
    super(props);
    // Handle the URL params for userid
    // If the userid is "mod" you are the moderator
    // Else you are that userid
    let uid = parseInt(this.props.params.userid)
    let is_mod = this.props.params.mod=="mod"
    
    this.state = { 
      nowtime: 0, // the current time
      paused: false, // whether or not we are moving forward in time
      infobox: "", // the text inside of the infobox
      show_users: false,
      is_moderator: is_mod,
      my_user_id: uid,
      show_editing: false,
      currently_editing_id: 0,
      currenlty_editing_type: "room",
    } 
  }

  componentDidMount() {
    // local user tracks time at a high frequency
    this.timerID = setInterval(
      () => this.tick(),
      100
    );
    // moderator sends a clock correction at a lower frequency 
    this.moderator_clock_correction_timerID = setInterval(
      () => this.moderatorClockCorrection(this.state.nowtime),
      1000
    );
    this.user_last_seen_timerID = setInterval(
      () => this.updateUserLastSeen(), 2000
    );
    // Listen for changes to the current time (both moderator and normal user)
    let db_current_time = this.props.db.child('current_time');
    db_current_time.on("value", (snapshot) => {
      let t = snapshot.val();
      this.setState({
        nowtime: t
      });
    });
  }

  componentDidUpdate() {
    if(this.props.room.paused != this.state.paused){
      this.setState({
        paused: this.props.room.paused
      })
    }
  }

  updateUserLastSeen(){
    if(this.state.my_user_id>=0){
      if(!this.props.room.users[this.state.my_user_id]){
        // My user has been deleted. do not update
        return;
      }
      this.props.db.child("users/"+this.state.my_user_id).update({ last_seen: firebase.database.ServerValue.TIMESTAMP });
    }
    // This is a hack to get the current server timestamp
    this.props.db.update({ last_server_timestamp: firebase.database.ServerValue.TIMESTAMP });
  }




  // ROOM

  editRoom = (title, subheader, total_time) => {
    total_time = parseInt(total_time);
    total_time = Math.floor(total_time/10)*10;

    if(this.state.is_moderator){
      this.props.db.update({title: title, subheader: subheader, total_time: total_time });
      //console.log(total_time, this.state.nowtime)
      if(total_time <= this.state.nowtime){
        // we have shrunk the room beyond the current time
        // to avoid being stranded in the void, we must change the current time
        this.updateTheTime(total_time);
        this.updatePaused(true)
      }
    }
  }


  // SECTION 
  updateSectionMove = (section_id, start) => {
             start = parseFloat(start)

    if(this.state.is_moderator){
      this.props.db.child("sections/" + section_id).update({start: start});
    }
  }
  updateSectionResize = (section_id, duration) => {
         duration = parseFloat(duration)

    if(this.state.is_moderator){
      this.props.db.child("sections/" + section_id).update({duration: duration });
    }
  }
  removeSection = (section_id) => {
    if(this.state.is_moderator){
      // don't remove the last section
      if(this.countChildren(this.props.room.sections)==1){
        return;
      }
      this.props.db.child("sections/" + section_id).remove()
    }
  }
  createSection = (start) => {
     start = parseFloat(start)

    //let new_section_id = this.props.room.sections.length;
    //let section_ids = Object.keys(this.props.room.sections).map(parseFloat) // why the fuck does parseInt return NaN
    let new_section_id;
    if(this.state.is_moderator){
      if(!this.props.room.sections){
        new_section_id = 0;
      }else{
        new_section_id = Math.max(...Object.keys(this.props.room.sections))+1;  
      }
      
      if(start<0){
        start=0;
      }
      let hue = Math.floor(Math.random()*360);
      this.props.db.child("sections/" + new_section_id).set({start: start, duration: 60, infobox: "", hue: hue });
       this.changeCurrentlyEditing("secction",new_section_id)

    }
  }
  editSection = (section_id, hue, infobox, title) => {
    hue = parseInt(hue)
    if(this.state.is_moderator){
      this.props.db.child("sections/" + section_id).update({hue: hue, infobox: infobox, title: title });
    }
  }

  // BLOCK 
  updateBlockMove = (block_id, user_id, start) => {
      user_id = parseInt(user_id)
      start = parseFloat(start)

    if(this.state.is_moderator){
      this.props.db.child("blocks/" + block_id).update({start: start, user: user_id });
    }
  }
  updateBlockResize = (block_id, duration) => {
    duration = parseFloat(duration)

    if(this.state.is_moderator){
      this.props.db.child("blocks/" + block_id).update({duration: duration });
    }
  }
  removeBlock = (block_id) => {
    // don't remove the last block
    if(this.countChildren(this.props.room.blocks)==1){
      return;
    }
    if(this.state.is_moderator){
      this.props.db.child("blocks/" + block_id).remove()
    }
  }
  createBlock = (user_id, start) => {     
    user_id = parseInt(user_id)
    start = parseFloat(start)

    //let new_block_id = this.props.room.blocks.length;
    //let block_ids = Object.keys(this.props.room.blocks).map(parseFloat) // why the fuck does parseInt return NaN
    let new_block_id;
    if(this.state.is_moderator){
      if(!this.props.room.blocks){
        new_block_id = 0;
      }else{
        new_block_id = Math.max(...Object.keys(this.props.room.blocks))+1;  
      }
      if(user_id<0){
        user_id=0 // prevent bugs
      }
      if(start<0){
        start=0;
      }
      // By default, set a random hue 
      let hue = Math.floor(Math.random()*360);
      // However, if there is already a block in this row with a hue, find any block and use that hue
      for(let block_id in this.props.room.blocks){
        let block = this.props.room.blocks[block_id]
        if(block.user==user_id){
          if(block.hue){
            hue = block.hue
          }
        }
      }
      this.props.db.child("blocks/" + new_block_id).set({start: start, user: user_id, duration: 60, infobox: "", hue: hue }).then(()=>
        this.changeCurrentlyEditing("block",new_block_id)
      );
    }
  }
  editBlock = (block_id, hue, infobox) => {
    if(this.state.is_moderator){
      this.props.db.child("blocks/" + block_id).update({hue: hue, infobox: infobox });
    }
  }


  createUser = () => {
    //let new_block_id = this.props.room.blocks.length;
    //let block_ids = Object.keys(this.props.room.blocks).map(parseFloat) // why the fuck does parseInt return NaN
    let new_user_id;
    if(this.state.is_moderator){
      if(!this.props.room.users){
        new_user_id = 0;
      }else{
        new_user_id = Math.max(...Object.keys(this.props.room.users))+1;  
      }
      if(new_user_id<0){
        new_user_id=0 // prevent bugs
      }
      this.props.db.child("users/" + new_user_id).set({name: "Participant "+new_user_id, infobox: "test" });
      this.changeCurrentlyEditing("user",new_user_id)
    }
  }

  // count the number of child in the firebase object/array/dictionary thingy
  // note: firebase, in an attempt to be "clever", will sometimes convert an associative array to a list, depending on the keys
  // this is a robust method for counting how many children the object has 
  countChildren = (parent) => {
    let count=0;
    for(let i in parent){
      count++;
    }
    return count;
  }

  removeUser = (user_id) => {
    if(this.state.is_moderator){
      // don't remove the last user
      if(this.countChildren(this.props.room.users)==1){
        return;
      }
      // first remove all the blocks that belong to that user
      for(let block_id in this.props.room.blocks){
        let block = this.props.room.blocks[block_id]
        if(block.user==user_id){
          // remove this block
          this.props.db.child("blocks/" + block_id).remove()
        }
      }
      // now remove the user
      this.props.db.child("users/" + user_id).remove()
    }
  }
  editUser = (user_id, infobox, name) => {
    if(this.state.is_moderator){
      this.props.db.child("users/" + user_id).update({infobox: infobox, name: name });
    }
  }




  updatePaused = (paused) => {
    paused = paused ? 1 : 0
    if(this.state.is_moderator){
      this.props.db.update({paused: paused})
    }
  }




  componentWillUnmount() {
    clearInterval(this.timerID);
    clearInterval(this.moderator_clock_correction_timerID);
    clearInterval(this.user_last_seen_timerID);
  }

  // When the (moderator) changes the time (play/pause, ff, rw, skip) call it here
  updateTheTime(t){
    if(this.state.is_moderator){
      this.setState({
        nowtime: t
      });
      this.moderatorClockCorrection(t);
    }
  }

  // moderator sends a clock correction to the database at a lower frequency 
  moderatorClockCorrection(t){

    if(this.state.is_moderator){
      this.props.db.update({current_time: t})
    }
  }

  // local user tracks time at a high frequency
  tick() {
    if(!this.state.paused){
      if(this.state.nowtime+0.1 > this.props.room.total_time){
        // We have ended
        this.setState({
          paused: true
        });
        this.updatePaused(true)
      }else{
        // we're still going (local update of time, not reacting to DB or moderator clock correction)
        this.setState({
          nowtime: this.state.nowtime+0.1
        });
      }
    }
  }


  // call this when changing the playhead position
  movePlayhead = (new_time) => {
    // prevent playhead from going beyond totaltime
    if(new_time > this.props.room.total_time){
      new_time = this.props.room.total_time
    }
    this.updateTheTime(new_time);
  }

  clickPlayPause = () => {
    // If we're at the end of the timeline, play button will send it back to the start
    if(this.state.nowtime >= this.props.room.total_time){
      this.updateTheTime(0);
    // Else switch play-pause
    }else{
      let paused = !this.state.paused;
      this.setState({
        paused: paused
      });
      this.updatePaused(paused)

    }
  }

  clickSkipForward = () => {
    let t = get_forward_time(this.props.room, this.state.nowtime, 0)
    this.updateTheTime(t);
  };

  clickSkipBackward = () => {
    let t = get_rewind_time(this.props.room, this.state.nowtime, 2)
    this.updateTheTime(t);
  };

  changeInfobox = (text) => {
    this.setState({
      infobox: text
    });
  }

  openUsers = () => {
    this.setState({
      show_users: true
    });
  }

  closeUsers = () => {
    this.setState({
      show_users: false
    });
  }



  changeCurrentlyEditing = (edit_type, edit_id) => {
    if(!this.state.is_moderator) return;
    // Validate that the type is one of these four
    edit_id = parseInt(edit_id)
    if(["block","section","room","user"].indexOf(edit_type)>-1){
      if(this.state.currently_editing_id == edit_id && this.state.currently_editing_type == edit_type){
        // If the type and id are identical, then unset them (closing the window
        this.setState({
          currently_editing_id: 0,
          currently_editing_type: undefined
        })
        this.closeEditing()
      }else{
        // update the currently editing element
        this.setState({
          currently_editing_id: edit_id,
          currently_editing_type: edit_type
        })
        this.openEditing()
      }

    }
    return false
  }
  openEditing = () => {
    if(!this.state.is_moderator) return;

    this.setState({
      show_editing: true
    });
  }

  closeEditing = () => {
    this.setState({
      show_editing: false,
      currently_editing_id: 0,
      currently_editing_type: undefined
    });
  }







  render() {
    let room = this.props.room;
    let current_section = get_current_section(room, this.state.nowtime);
    let is_empty = !current_section.duration;

    let section_title = current_section.title;
    let section_duration = current_section.duration;
    let current_users = get_current_users(room, this.state.nowtime);
    let current_user_names = current_users.user_names;

    // This is the current event, which lasts until the next change. 
    // The next change could either a change in topic-section or user-block
    let clock_section_start = get_rewind_time(this.props.room, this.state.nowtime, 0);
    let clock_section_duration = get_forward_time(this.props.room, this.state.nowtime, 0) - clock_section_start;
    // Next block for my user. This is used to display alerts to my user before their next block
    let my_next_block = get_my_next_block(room, this.state.nowtime, this.state.my_user_id)
    // The next event countdown will be shown if there is black section. 
    let next_section = get_next_section(this.props.room, this.state.nowtime)

    //console.log(my_next_block)
    return (
      <div className={this.state.is_moderator?"is_mod":""}>
        <Countdown
          room={room}
          next_section={next_section}
          current_users={current_users}
          my_user_id={this.state.my_user_id}
          my_next_block={my_next_block}
          is_moderator={this.state.is_moderator}
          section={current_section}
          clock_section_start={clock_section_start}
          clock_section_duration={clock_section_duration}
          names={current_user_names}
          nowtime={this.state.nowtime}
          is_empty={is_empty}
          is_paused={this.state.paused}
          click_playpause={this.clickPlayPause}
          click_skip_forward={this.clickSkipForward}
          click_skip_backward={this.clickSkipBackward}
          change_infobox={this.changeInfobox} />
        <Timeline
          room={room}
          move_playhead={this.movePlayhead}

          update_section_move={this.updateSectionMove}
          update_section_resize={this.updateSectionResize}
          remove_section={this.removeSection}
          create_section={this.createSection}

          update_block_move={this.updateBlockMove}
          update_block_resize={this.updateBlockResize}
          remove_block={this.removeBlock}
          create_block={this.createBlock}

          create_user={this.createUser}

          my_user_id={this.state.my_user_id}
          nowtime={this.state.nowtime}
          infobox={this.state.infobox}
          open_users={this.openUsers}
          show_users={this.state.show_users}
          is_moderator={this.state.is_moderator}
          change_infobox={this.changeInfobox} 

          change_currently_editing={this.changeCurrentlyEditing}
          currently_editing_id={this.state.currently_editing_id}
          currently_editing_type={this.state.currently_editing_type}
          close_editing={this.closeEditing}
          open_editing={this.openEditing}
          />
        <UsersOnline
          show={this.state.show_users}
          my_user_id={this.state.my_user_id}
          room={room}
          close_users={this.closeUsers}
        />
        {this.state.is_moderator && <CurrentlyEditing
          show={this.state.show_editing}
          room={room}
          currently_editing_id={this.state.currently_editing_id}
          currently_editing_type={this.state.currently_editing_type}
          close_editing={this.closeEditing}

          edit_block={this.editBlock}
          edit_section={this.editSection}
          edit_room={this.editRoom}
          edit_user={this.editUser}

          remove_block={this.removeBlock}
          remove_section={this.removeSection}
          remove_user={this.removeUser}

        />}
        }
      </div>
    )
  }
}
export default TimelineContainer;