// App.js
import React from 'react';
import './App.css';

import Draggable from 'react-draggable';
import {DraggableCore} from "react-draggable";
import { Resizable, ResizableBox } from 'react-resizable';
import {useState, useEffect, useRef} from 'react';

import { ScrollSync, ScrollSyncNode } from "scroll-sync-react";


const _zoom_power = 1.3;


class Timeline extends React.Component {

  constructor(props){
    super(props)
    // This reference is used to calculate the window width
    this.timeline_middle = React.createRef()
    this.times_container = React.createRef()
    this.block_container = React.createRef()
    // As the window resizes, re-render the timeline
    window.addEventListener('resize', ()=>this.setState())
  }

  state = {
    is_resizing:false,
    ppm: 100,
    ppr: 40,
  };

  handleBlockResize = (e) => {
    //console.log("hi")
    this.setState({is_resizing:true})
    return false;

  }
  handleBlockResizeStop = (e,d) => {
    this.setState({is_resizing:false})
    let new_width = d.size.width; 
    let new_duration = Math.floor(new_width/this.state.ppm*60/10)*10; // seconds, rounded to nearest 10;
    let block_id;
    try{
      block_id = d.node.parentElement.children[0].attributes["block_id"].value;
    }catch{
      block_id = d.node.children[0].attributes["block_id"].value;
    }
    this.props.update_block_resize(block_id, new_duration)

  }
  handleBlockDoubleClickBlock = (e) =>{
    // Remove BLock
    e.stopPropagation();
    let block_id = parseInt(e.target.attributes.block_id.value)
    //console.log(block_id)
    this.props.remove_block(block_id)

  }

  rowToUser = (row) =>{
    // The user_id doesn't equal the row number
    // Because a user in the middle of the rows can be sliced out 
    // Find the user_id 
    let count = 0
    for(let r in this.props.room.users){
      if(count==row){
        return r;
      }
      count++;
    }
    return null;
  }

  userToRow = (user_id) =>{
    // The user_id doesn't equal the row number
    // Because a user in the middle of the rows can be sliced out 
    // Find the row number
    let count = 0
    for(let r in this.props.room.users){
      if(r == user_id){
        return count;
      }
      count++;
    }
    return null;
  }

  handleBlockDoubleClickGrid = (e,d) =>{
    // Create BLock
    //console.log(e);
    //console.log(e,d)
    let time = Math.floor((e.nativeEvent.offsetX)/this.state.ppm * 60 / 10)*10;
    let row = Math.floor((e.nativeEvent.offsetY)/this.state.ppr);

    let user_id = this.rowToUser(row)
    this.props.create_block(user_id, time)
  }

  handleBlockDrag = (e, d) => {
    if(this.state.is_resizing){
      return false;
    }

    //console.log(e, d)
    let new_x = d.lastX + d.deltaX;
    let new_time = Math.round((new_x / this.state.ppm) * 60)
    new_time = Math.max(0,new_time) // can't be less than zero

    let new_y = d.lastY + d.deltaY;
    let new_row = Math.round(new_y / this.state.ppr)
    new_row = Math.max(0,new_row) // can't be less than zero
    let block_id = parseInt(d.node.children[0].attributes.block_id.value)
    //console.log(new_time, new_row, block_id)
    let new_user_id = this.rowToUser(new_row)

    this.props.update_block_move(block_id, new_user_id, new_time)

  };

  // Clicking the timecodes at the top
  // This will reset the playhead position (if mod)
  handleClickTimesContainer = (e) => {
    let x = e.nativeEvent.offsetX
    let new_time = x/this.state.ppm * 60;
    // round to nearest 10 seconds
    new_time = Math.floor(new_time/10)*10;
    this.props.move_playhead(new_time);
  };



  /// SECTIONS

  handleSectionResize = (e) => {
    //console.log("hi")
    this.setState({is_resizing:true})
    return false;

  }
  handleSectionResizeStop = (e,d) => {
    this.setState({is_resizing:false})
    let new_width = d.size.width; 
    let new_duration = Math.floor(new_width/this.state.ppm*60/10)*10; // seconds, rounded to nearest 10;
    let section_id;
    try{
      section_id = d.node.parentElement.children[0].attributes["section_id"].value;
    }catch{
      section_id = d.node.children[0].attributes["section_id"].value;
    }
    this.props.update_section_resize(section_id, new_duration)

  }
  handleSectionDoubleClickSection = (e) =>{
    // Remove BLock
    e.stopPropagation();
    let section_id = parseInt(e.target.attributes.section_id.value)
    //console.log(section_id)
    this.props.remove_section(section_id)

  }
  handleSectionDoubleClickGrid = (e,d) =>{
    // Create BLock
    //console.log(e);
    //console.log(e,d)
    let time = Math.floor((e.nativeEvent.offsetX)/this.state.ppm * 60 / 10)*10;
    //console.log(time,row)
    this.props.create_section(time)
  }

  handleSectionDrag = (e, d) => {
    if(this.state.is_resizing){
      return false;
    }

    //console.log(e, d)
    let new_x = d.lastX + d.deltaX;
    let new_time = Math.round((new_x / this.state.ppm) * 60)
    new_time = Math.max(0,new_time) // can't be less than zero

    let section_id = parseInt(d.node.children[0].attributes.section_id.value)
    //console.log(new_time, new_row, section_id)
    this.props.update_section_move(section_id, new_time)

  };


  // ZOOMING 
  zoomLeft = () => {
    let ppm = this.constrainColumnWidth(this.state.ppm/_zoom_power)
    this.setState({ppm: ppm});
    this.timeline_middle.current.scrollTo({
      left: this.timeline_middle.current.scrollLeft / _zoom_power
    })
  }
  zoomRight = () => {
    let ppm = this.constrainColumnWidth(this.state.ppm*_zoom_power)
    this.setState({ppm: ppm});
    this.timeline_middle.current.scrollTo({
      left: this.timeline_middle.current.scrollLeft * _zoom_power
    })
  }
  zoomUp = () => {
    let ppr = this.constrainRowHeight(this.state.ppr/_zoom_power)
    this.setState({ppr: ppr});
    this.timeline_middle.current.scrollTo({
      top: this.timeline_middle.current.scrollTop / _zoom_power
    })
  }
  zoomDown = () => {
    let ppr = this.constrainRowHeight(this.state.ppr*_zoom_power)
    this.setState({ppr: ppr});
    this.timeline_middle.current.scrollTo({
      top: this.timeline_middle.current.scrollTop * _zoom_power
    })
  }

  // Set zoom maximums on grid width according to the window size
  constrainColumnWidth = (ppm) => {
    if(this.block_container.current){
      //let bcw = this.block_container.current.clientWidth
      let bcw = ppm * this.props.room.total_time/60;
      let tmw = this.timeline_middle.current.clientWidth-200;
      //console.log(ppm, bcw, tmw)
      if(bcw < tmw){
        // there is empty space to the right of the blocks grid
        // tmw is the target width
        // reset the ppm to the minimum -->
        //this.setState({ppm: tmw/this.props.room.total_time})
        ppm = tmw / (this.props.room.total_time/60)
      }
    }
    return ppm
  }

  // Set zoom maximums on grid height according to the window size
  constrainRowHeight = (ppr) => {
    let num_rows = 0;
    for(let user in this.props.room.users){
      num_rows++;
    }
    if(this.block_container.current){
      //let bch = this.block_container.current.clientHeight; 
      let bch = ppr * num_rows;
      let tmh = this.timeline_middle.current.clientHeight-100;
      //console.log(ppr, bch, tmh)
      if(bch < tmh){
        // there is empty space to the bottom of the blocks grid
        // tmh is the target height
        // reset the ppr to the minimum -->
        
        ppr = tmh/num_rows
      }
    }
    return ppr
  }
  /*
  componentDidMount(){

    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }*/

  componentDidUpdate(){
    let new_ppm = this.constrainColumnWidth(this.state.ppm);
    let new_ppr = this.constrainRowHeight(this.state.ppr);
    if((new_ppm != this.state.ppm) || (new_ppr != this.state.ppr)){
      this.setState({
        ppm: new_ppm,
        ppr: new_ppr,
      });
    }
  }

  render() {

    //empty dependency array so it only runs once at render

    //const { deltaXyPos } = this.state;

    let num_rows = 0;
    for(let user in this.props.room.users){
      num_rows++;
    }

    let ppm = this.state.ppm; //pixels per minute; used to calculate how wide the grid is
    let ppr = this.state.ppr; //pixels per row; used to calculate how high the rows are

    let block_container_background = "repeating-linear-gradient(to bottom,\
      #fff0,#fff0 "+(ppr-2)+"px,#eee "+(ppr-2)+"px,#eee "+ppr+"px), \
      repeating-linear-gradient(to right,\
      #eee,#eee 2px,#fff 2px,#fff "+ppm+"px )";

    let sections_container_background = "repeating-linear-gradient(to right,\
      #eee,#eee 2px,#e0e0e0 2px,#e0e0e0 "+ppm+"px )";


    let participants = [];
    for(let i in this.props.room.users){
      let user= this.props.room.users[i]
      let is_editing = (this.props.currently_editing_type == "user") && (this.props.currently_editing_id == i);
      let is_me = i == this.props.my_user_id;
       participants.push(<div className={"participant "+ (is_editing?"is_editing":"") } 
        style={{height: "calc("+ppr+"px - 2px", fontWeight: is_me?"bold":""}}
        onContextMenu={(e)=>{ e.preventDefault(); this.props.change_currently_editing("user",i)}}
        onMouseEnter={()=>this.props.change_infobox((user.name||"") + "\n" + (user.infobox||""))}
        ><div className="participant-text">{user.name}</div></div>)
    }


    // SECTIONS

    // Moderator view
    //console.log(this.props.room.sections)
    //let sections_editable = this.props.room.sections.map((block, i) => {
    let sections_editable = [];
    for(let i in this.props.room.sections){
      let section = this.props.room.sections[i]
      let zoomsnap = 1; 
      let is_editing = (this.props.currently_editing_type == "section") && (this.props.currently_editing_id == i);

      // Zoom snap would theoretically let you snap to larger times the more you zoom out
      // Unfinished
      /*zoomsnap = Math.ceil(100/ppm)
      if(zoomsnap<=1) zoomsnap = 1;
      else if(zoomsnap<=3) zoomsnap = 3;
      else if(zoomsnap<=6) zoomsnap = 6;
      console.log(zoomsnap)*/
      sections_editable.push(
          <Draggable bounds="parent" 
           grid={[ppm/6*zoomsnap, 50]}
           defaultPosition={{
              x:ppm*section.start/60,
              y:0
            }}
            position={{
              x:ppm*section.start/60,
              y:0
            }}
            section_id={i}
            onDrag={this.handleSectionDrag}>
            <ResizableBox draggableOpts={{bounds:"parent",grid:[ppm/6*zoomsnap, 50]}}
              axis="x" 
                minConstraints={[ppm/6*zoomsnap, 50]} maxConstraints={[ppm*this.props.room.total_time, 50]}
                className="box  hover-handles" height={50} width={section.duration*ppm/60} 
                onResizeStart={this.handleSectionResize}
                onResizeStop={this.handleSectionResizeStop}
                            section_id={i}
                resizeHandles={['e','ne','se']}>
              <div className={"block-drag-wrapper section " + (is_editing?"is_editing":"") } 
                                            onDoubleClick={this.handleSectionDoubleClickSection}

                section_id={i}
                  style={{backgroundColor: "hsl("+section.hue+"deg 50% 50%)"}}
                  onContextMenu={(e)=>{ e.preventDefault(); this.props.change_currently_editing("section",i)}}
                              

                  onMouseEnter={()=>this.props.change_infobox((section.title||"") + "\n" + (section.infobox||""))}>
                <div section_id={i} className="block-text">{section.title} </div>
              </div>
            </ResizableBox>
          </Draggable>)
    }

    // Participant View
    let sections_uneditable = [];
    for(let i in this.props.room.sections){
      let section = this.props.room.sections[i]
      sections_uneditable.push(
          <div
           style={{transform: "translate("+ppm*section.start/60+ "px, 0px)",
            position: "absolute",
            height: "50px",
            width:section.duration*ppm/60  }}>
            
              <div className="block-drag-wrapper section" style={{backgroundColor: "hsl("+section.hue+"deg 50% 50%)", cursor: "default"}}
                  onMouseEnter={()=>this.props.change_infobox((section.title||"") + "\n" + (section.infobox||""))}>
                <div className="block-text">{section.title}</div> 
              </div>
          </div>)
    }


    // let sections = this.props.room.sections.map((section, i) => {     
    //    return (<div className="section" style={{
    //      backgroundColor: "hsl("+section.hue+"deg 50% 50%)",
    //      position: "absolute",
    //      left: "calc("+(ppm/60*section.start)+"px)",
    //      width: "calc("+(ppm/60*section.duration)+"px)"
    //     }}
    //     onMouseEnter={()=>this.props.change_infobox(section.title)}
    //    ><span>{section.title}</span></div>)
    // })

    let time_markers = []
    for(let i=0;i<this.props.room.total_time/60;i+=1){
      time_markers.push(<div className="time-marker" style={{width:ppm}}><span>{i}:00</span></div>)
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    let clock_total_time = "00:00:00"
    let seconds = Math.floor(this.props.room.total_time) % 60;
    let minutes = Math.floor(this.props.room.total_time / 60)/*% 60*/
    clock_total_time = zeroPad(minutes,2) + ":" + zeroPad(seconds,2)

    let clock_current_time = "00:00:00"
    seconds = Math.floor(this.props.nowtime) % 60;
    minutes = Math.floor(this.props.nowtime / 60) /*% 60*/
    clock_current_time = zeroPad(minutes,2) + ":" + zeroPad(seconds,2)

    // Moderator view
    //console.log(this.props.room.blocks)
    //let blocks_editable = this.props.room.blocks.map((block, i) => {
    let blocks_editable = [];
    for(let i in this.props.room.blocks){
      let block = this.props.room.blocks[i]
      let user = this.props.room.users[block.user];
      let user_name = user ? user.name : "";


      // The user_id doesn't equal the row number
      // Because a user in the middle of the rows can be sliced out 
      // Find the row number
      let row = this.userToRow(block.user)

      let zoomsnap = 1; 
      let is_editing = (this.props.currently_editing_type == "block") && (this.props.currently_editing_id == i);
      //console.log(this.props.currently_editing_type,this.props.currently_editing_id)
      // Zoom snap would theoretically let you snap to larger times the more you zoom out
      // Unfinished
      /*zoomsnap = Math.ceil(100/ppm)
      if(zoomsnap<=1) zoomsnap = 1;
      else if(zoomsnap<=3) zoomsnap = 3;
      else if(zoomsnap<=6) zoomsnap = 6;
      console.log(zoomsnap)*/
      blocks_editable.push(
          <Draggable bounds="parent" 
           grid={[ppm/6*zoomsnap, ppr]}
           defaultPosition={{
              x:ppm*block.start/60,
              y:ppr*row
            }}
            position={{
              x:ppm*block.start/60,
              y:ppr*row
            }}
            block_id={i}
            onDrag={this.handleBlockDrag}>
            <ResizableBox draggableOpts={{bounds:"parent",grid:[ppm/6*zoomsnap, ppr]}}
              axis="x" 
                minConstraints={[ppm/6*zoomsnap, ppr]} maxConstraints={[ppm*this.props.room.total_time, ppr]}
                className="box  hover-handles" height={ppr} width={block.duration*ppm/60} 
                onResizeStart={this.handleBlockResize}
                onResizeStop={this.handleBlockResizeStop}
                            block_id={i}
                resizeHandles={['e','ne','se']}>
              <div className={"block-drag-wrapper " + (is_editing?"is_editing":"") } 
                                            onDoubleClick={this.handleBlockDoubleClickBlock}

                block_id={i}
                  style={{backgroundColor: "hsl("+block.hue+"deg 50% 50%)"}}
                  onContextMenu={(e)=>{ e.preventDefault(); this.props.change_currently_editing("block",i)}}

                  onMouseEnter={()=>this.props.change_infobox(block.infobox || user_name)}>
                <div block_id={i} className="block-text">{block.infobox || user_name} </div>
              </div>
            </ResizableBox>
          </Draggable>)
    }

    // Participant View
    let blocks_uneditable = [];
    for(let i in this.props.room.blocks){
      let block = this.props.room.blocks[i]
      let user = this.props.room.users[block.user];
      let user_name = user ? user.name : "";
      //let row = this.props.room.users.indexOf(block.user);
      let row = this.userToRow(block.user)
      blocks_uneditable.push(
          <div
           style={{transform: "translate("+ppm*block.start/60+ "px, "+(ppr*parseInt(row))+"px)",
            position: "absolute",
              height:ppr, width:block.duration*ppm/60  }}>
            
              <div className="block-drag-wrapper" style={{backgroundColor: "hsl("+block.hue+"deg 50% 50%)", cursor: "default"}}
                  onMouseEnter={()=>this.props.change_infobox(block.infobox || user_name)}>
                <div className="block-text">{block.infobox || user_name}</div> 
              </div>
          </div>)
    }

    let is_title_editing = (this.props.currently_editing_type == "room");

     
    return (
      <div className="timeline">


        <div className="timeline-top"
                  onMouseEnter={()=>this.props.change_infobox(this.props.room.subheader)}>
          <div className={"timeline-top-title " + (is_title_editing?"is_editing":"") } 
                            onContextMenu={(e)=>{ e.preventDefault(); this.props.change_currently_editing("room",0)}}

            onMouseEnter={()=>this.props.change_infobox(this.props.room.title+"\n"+this.props.room.subheader)}>
            {this.props.room.title}</div>
          <div className="timeline-top-clock">
            <span className="timeline-top-clock-current">{clock_current_time}</span>
            <span className="timeline-top-clock-center"> / </span>
            <span className="timeline-top-clock-total">{clock_total_time}</span>
          </div>
        </div>



        <div className="timeline-middle" ref={this.timeline_middle}>
          <div className="timeline-clock"></div>


          <div className="times-container" style={{width: ppm*this.props.room.total_time/60+"px"}}
            ref={this.times_container}>
            {time_markers}

             <div className="times-container-click" onClick={this.handleClickTimesContainer}></div>

          </div>

          <div className="timeline-zoom">
              <div className="zoom-up" onClick={this.zoomUp}><div/></div>
              <div className="zoom-down" onClick={this.zoomDown}><div/></div>
              <div className="zoom-left" onClick={this.zoomLeft}><div/></div>
              <div className="zoom-right" onClick={this.zoomRight}><div/></div>
          </div>
          
          <div className="sections-container" style={{
            width: ppm * this.props.room.total_time/60 + "px",
            background: sections_container_background }}>
            
            {!this.props.is_moderator && sections_uneditable}
            {this.props.is_moderator && sections_editable}
            {this.props.is_moderator && <div 
            onContextMenu={(e)=>{ e.preventDefault(); }} 
            onDoubleClick={this.handleSectionDoubleClickGrid} className="block-grid-clicker"></div>}

            <div className="section-time-fill" style={{width:(ppm/60*this.props.nowtime)+"px"}}></div>
          </div>

          <div className="participants-container">
            {participants}
            {this.props.is_moderator && <div className="add-participant-button" onClick={this.props.create_user}>+</div>}

          </div>


          <div className="block-container" style={{
            height: (ppr*num_rows)+"px", 
            background: block_container_background,
            width: ppm*this.props.room.total_time/60+"px" }}
            ref={this.block_container}
            >
            {!this.props.is_moderator && blocks_uneditable}

            {this.props.is_moderator && blocks_editable}
            {this.props.is_moderator && <div 
              onContextMenu={(e)=>{ e.preventDefault(); }} 
              onDoubleClick={this.handleBlockDoubleClickGrid} className="block-grid-clicker"></div>}
          </div>
          
          <div className="playhead" style={{
            left: "calc(200px + "+(ppm/60*this.props.nowtime)+"px)",
            height: "calc(50px + 15px + "+ ppr*num_rows+"px)"}}> </div>
        </div>

        <div className="infobox">

          {false && <div className="infobox-title"></div>}
          <div className="infobox-text">{this.props.infobox}</div>
          <div className={"button-users " + (this.props.show_users?"hide":"")} onClick={this.props.open_users}></div>

        </div>
      </div>

    );
  }
}

export default Timeline;